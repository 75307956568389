<template>
  <div class="paginator">
    <hcc-button
      size="xs"
      :color="actualPage === 1 ? 'pagination' : 'primary'"
      :disabled="actualPage === 1"
      @click="changePage(actualPage - 1)"
      class="paginator-button"
    >
      {{ $t("pagination.previous") }}
    </hcc-button>
    <div class="paginator-item" v-if="actualPage > 2">
      <hcc-button
        size="xs"
        color="pagination"
        @click="changePage(1)"
        class="paginator-button"
      >
        1
      </hcc-button>
      ...
    </div>
    <div v-for="page in visibleRange" :key="page" class="paginator-item">
      <hcc-button
        size="xs"
        variant="fill"
        :color="page === actualPage ? 'primary' : 'pagination'"
        @click="changePage(page)"
        class="paginator-button"
      >
        {{ page }}
      </hcc-button>
    </div>
    <div class="paginator-item" v-if="range.length - actualPage > 1">
      ...
      <hcc-button
        size="xs"
        variant="fill"
        color="pagination"
        @click="changePage(range.length)"
        class="paginator-button"
      >
        {{ range.length }}
      </hcc-button>
    </div>

    <hcc-button
      size="xs"
      :color="actualPage === range.length ? 'pagination' : 'primary'"
      @click="changePage(actualPage + 1)"
      :disabled="actualPage === range.length"
      class="paginator-button"
    >
      {{ $t("pagination.next") }}
    </hcc-button>
  </div>
</template>
<script>
import HccButton from '@/components/shared/HccButton/index.vue';

export default {
  components: { HccButton },
  props: {
    total: Number,
    actualPage: Number,
    rowsPerPage: Number,
    pageChanged: {
      type: Function,
    },
  },
  methods: {
    changePage(newPage) {
      this.pageChanged({ currentPage: newPage });
      this.$emit('page-change', newPage);
    },
  },
  computed: {
    range() {
      return new Array(Math.ceil(this.total / this.rowsPerPage)).fill().map((_, i) => i + 1);
    },

    visibleRange() {
      if (this.actualPage === 1) {
        return this.range.slice(0, 2);
      }
      if (this.actualPage === this.range.length) {
        return this.range.slice(this.range.length - 2);
      }

      return this.range.slice(this.actualPage - 2, this.actualPage + 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.paginator {
  display: flex;
  justify-content: flex-end;
  padding: 10px 30px;
  padding-right: 0px;

  .paginator-item {
    margin: 0 3px;
  }

  .button.paginator-button {
    font-size: 0.8em !important;
  }
}
</style>
